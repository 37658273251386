import { useState, useRef, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';

export default function Ranking(props) {
    const [viewingMode, setViewingMode] = useState('street');
    const interval_01 = useRef(null);
    const interval_02 = useRef(null);

    let dataFetched = {
        street: {
            name: '告警街镇',
            labels: ['丰翔路', '双丁路', '两港大道', '金山大道', '水产西路', '创新路', '云平路', '新元路', '蒙山路'],
            data: [12, 9, 8, 8, 6, 3, 3, 2, 1],
        },
        alarmType: {
            name: '告警类型',
            labels: ['违规占道', '跨门营业', '垃圾厢房', '车辆拥堵', '人流密集'],
            data: [21, 8, 4, 3, 1],
        },
    };

    let chartOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          grid: {
            left: '20%',
            right: '0',
            bottom: '5%',
            top: '10%',
            containLabel: false
          },
          yAxis: {
            type: 'category',
            boundaryGap: false,
            axisLine: {
              show: false,
            },
            inverse: true,
          },
          xAxis: {
            show:false,
            type: 'value',
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            }
          },
          series: [
            {
              type: 'bar',
              label: {
                show: true
              },
              emphasis: {
                focus: 'series'
              },
            }
        ]
    }

    chartOption.series[0].name = dataFetched[viewingMode].name;
    chartOption.yAxis.data = dataFetched[viewingMode].labels;
    chartOption.series[0].data = dataFetched[viewingMode].data;


    useEffect(()=>{
      if(interval_01.current)clearInterval(interval_01.current);
      if(interval_02.current)clearInterval(interval_02.current);
      if(props.demoMode){

        setViewingMode('alarmType');
        interval_01.current = setInterval(() => {
          setViewingMode('alarmType');
        }, 24000);

        setTimeout(() => {
          setViewingMode('street');
        }, 12000);
        interval_01.current = setInterval(() => {
          setTimeout(() => {
            setViewingMode('street');
          }, 12000);
        }, 24000);
      }},[props.demoMode]);

    return (
        <div id="wk-dashboard-widget-ranking" className="wk-dashboard-widget">
            <h2 className="wk-c-title">
              <span className="wk-c-icon"><img src="./images/icon-ranking.png" /></span> <span className="wk-c-text">告警排行</span>
            </h2>
            <div className="wk-c-content">
                <div><span onClick={e=>{setViewingMode('street');}} className={viewingMode=='street'?'wk-c-highlight':''}>告警街镇</span> | <span onClick={e=>{setViewingMode('alarmType');}} className={viewingMode=='alarmType'?'wk-c-highlight':''}>告警类型</span></div>
              <div id="wk-chart-ranking">
              <ReactECharts option={chartOption} style={{ height: '12rem' }} />
              </div>
            </div>
          </div>
    );
}