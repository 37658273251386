// import logo from './logo.svg';
// import './App.css';
import { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import _ from 'lodash';
import Alarm from './modules/alarm/alarm';
import CO2 from './modules/co2/co2';
import ConnectAll from './modules/connectall/connectall';
import Discover from './modules/discover/discover';
import Header from './modules/header/header';
import WKMap from './modules/map/map';
import Mesh from './modules/mesh/mesh';
import Ranking from './modules/ranking/ranking';



function App() {

  const queryParameters = new URLSearchParams(window.location.search);



  const [loginData, setLoginData] = useState(null);
  const [liveDataStatus, setLiveDataStatus] = useState('loading');  //loading, failed
  const [liveData, setLiveData] = useState(null);
  const [appData, setAppData] = useState(null);
  const [newAlarmData, setNewAlarmData] = useState(null);
  const [socketStatus, setSocketStatus] = useState('loading');
  const [demoMode, setDemoMode] = useState(false);

  // const [updateDataInterval, setUpdateDataInterval] = useState(null);
  const updateDataInterval = useRef(null);


  const socket = useRef(null);
  const loadingUserIndex = useRef(false);
  const loadingLogin = useRef(false);
  const loadingBigscreen = useRef(false);


  function htmlspecialchars_decode(str) {
    str = str.replace(/&amp;/g, '&');
    str = str.replace(/&lt;/g, '<');
    str = str.replace(/&gt;/g, '>');
    str = str.replace(/&quot;/g, '"');
    str = str.replace(/&#039;/g, "'");
    return str;
  }


  useEffect(() => {
    if (liveDataStatus == 'loading') {

      let token = queryParameters.get("token");

      if(token){
        if(loadingUserIndex.current) return;
        
        console.log('用户已登录，token：',token);
        loadingUserIndex.current = true;
        fetch(`http://139.196.53.184:88/api/user/index`, {
          method: "GET",
          headers: {
            token: token,
          }
        }).then((response) => response.json()).then(responseJson => {
          if(responseJson.data && responseJson.data.user_id){
            let prepLoginData = _.merge(responseJson.data, {token:token});
            setLoginData(prepLoginData);
            console.log('根据token取得用户数据：', prepLoginData);
          }
        });
      }else{

        if(loadingLogin.current) return;
        console.log('用户未登录，使用默认用户名密码');
        loadingLogin.current = true;
        fetch(`http://139.196.53.184:88/api/user/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          // body: 'account=13665180299&password=123456',
          body: 'account=13524774664&password=123456',

        }).then((response) => response.json()).then(responseJson => {

          let userToken = responseJson.data.userinfo.token || null;

          //token failed
          if (!userToken) {
            console.log('登录失败，token 未获取：',responseJson.data);
            setLiveDataStatus('failed');
            return;
          }

          //token OK
          let prepLoginData = _.merge(responseJson.data.userinfo, {user_id:responseJson.data.baseinfo.user_id || ''}, {name:responseJson.data.primary.nickname || ''});
          setLoginData(prepLoginData);
          console.log('登录成功，用户数据：', prepLoginData);

        });

      }

      /* setLiveDataStatus('failed');
      return; */

      

    } else if (liveDataStatus == 'failed') {
      fetch('/data/bigscreen.json').then((response) => response.json()).then(responseJson => {
        console.log('登录失败，使用演示数据：', responseJson);
        setLiveData(responseJson);
        setLiveDataStatus('loaded-mock');
      });
    }

  }, []);


  if(loginData){
    if(!socket.current){
      socket.current = io("http://139.196.53.184:2120");
      socket.current.on("connect", () => {
        console.log('WS成功连接：', 'u-' + loginData.user_id + '-' + loginData.id);
      });
    
      socket.current.emit('login', 'u-' + loginData.user_id + '-' + loginData.id);
      socket.current.on("new_msg", (res) => {
        let alarmInfo = JSON.parse(htmlspecialchars_decode(res));
        console.log('📢【收到WS消息】: ', alarmInfo);
        if (alarmInfo && alarmInfo.alarmseq && alarmInfo.alarmtime) {
          // console.log('——消息类型为[告警]')
          setNewAlarmData(alarmInfo);
        }
        return false;
      });
    }


    if(!loadingBigscreen.current){
      loadingBigscreen.current = true;
      fetch('http://139.196.53.184:88/api/dashboard/bigscreen', {
        method: "GET",
        headers: {
          token: loginData.token,
        }
      }).then((response) => response.json()).then(responseJson => {
    
        //liveData Failed
        if (!responseJson) {
          console.log('大屏数据加载失败：', responseJson);
          setLiveDataStatus('failed');
          return;
        }
    
        //liveData OK
        console.log('🖥️大屏数据加载成功: ', responseJson);
        setLiveData(responseJson);
        setLiveDataStatus('loaded-live');
      });
    }
  }




  // setLiveDataStatus('cancelled');
  // return;








  function prepAppData(dataLoaded) {
    if (!dataLoaded || !loginData) return null;

    let dataPrep = {
      header: {
        logininfo: loginData
      },
      mesh: {
        pm2_5: {
          current: dataLoaded.data.uphold['avg(pm25)'] || 0,
        },
        pm10: {
          current: dataLoaded.data.uphold['avg(pm10)'] || 0,
        },
        noise: {
          current: dataLoaded.data.uphold['avg(noise)'] || 0,
        },
        co2: {
          current: dataLoaded.data.uphold['avg(co2)'] || 0,
        },
        /* traffic: {
          current: dataLoaded.data.uphold['avg(trafficvolume)'] || 0,
        },
        pedestrian: {
          current: dataLoaded.data.uphold['avg(flow)'] || 0,
        }, */
        /* congestion: {
          current: data.uphold.avg(co2) || 0,
        }, */
      },
      alarm: {
        alarmlist: dataLoaded.data.alarmlist || []
      },
      map: {
        devicelist: [
          {
            group: '上海市-闵行区-办公室',
            devices: dataLoaded.data.map.devicelist.map((device, index) => {
              return {
                id: device.id + '-' + index,
                coordinates: device.lng + ',' + device.lat,
                type: '智能+照明',
                deviceid: device.deviceid,
                alarm: []
              }
            })
          },
          {
            group: '上海市-宝山区-大场镇',
            devices: [
              {
                id: 'g1-d1',
                coordinates: '121.386757,31.314056',
                type: '照明',
              },
              {
                id: 'g1-d2',
                coordinates: '121.391599,31.325605',
                // alarm: true,
                type: '智能+照明',
              },
              {
                id: 'g1-d3',
                coordinates: '121.404135,31.323658',
                type: '照明',
              },
            ]
          },
          {
            group: '上海市-嘉定区-嘉定镇街道',
            devices: [
              {
                id: 'g2-d1',
                coordinates: '121.258618,31.336952',
                type: '照明',
              },
              {
                id: 'g2-d2',
                coordinates: '121.258811,31.335973',
                // alarm: true,
                type: '智能+照明',
              },
              {
                id: 'g2-d3',
                coordinates: '121.253219,31.340584',
                type: '照明',
              },
            ]
          },
          {
            group: '上海市-浦东新区-泥城镇',
            devices: [
              {
                id: 'g3-d1',
                coordinates: '121.838501,30.885356',
                type: '照明',
              },
              {
                id: 'g3-d2',
                coordinates: '121.828216,30.887812',
                type: '智能+照明',
              },
            ]
          },
          {
            group: '上海市-金山区-石化街道',
            devices: [
              {
                id: 'g4-d1',
                coordinates: '121.324379,30.738932',
                // alarm: true,
                type: '照明',
              },
              {
                id: 'g4-d2',
                coordinates: '121.337665,30.765695',
                type: '智能+照明',
              },
            ]
          },
          {
            group: '上海市-宝山区-杨行镇',
            devices: [
              {
                id: 'g5-d1',
                coordinates: '121.435544,31.367074',
                type: '智能+照明',
              },
              {
                id: 'g5-d2',
                coordinates: '121.423763,31.368172',
                type: '照明',
              },
            ]
          },
          {
            group: '上海市-青浦区-赵巷镇',
            devices: [
              {
                id: 'g6-d1',
                coordinates: '121.199725,31.165346',
                type: '智能+照明',
              },
              {
                id: 'g6-d2',
                coordinates: '121.205843,31.163337',
                type: '照明',
              },
            ]
          },
        ]

      }
    }

    dataLoaded.data.alarmlist.forEach((iAlarm) => {
      let deviceFound = dataPrep.map.devicelist[0].devices.find(iDevice => { return iDevice.deviceid == iAlarm.deviceid; });
      if (deviceFound) {
        deviceFound.alarm.push(iAlarm);
        // console.log('alarm added to device: ', deviceFound);
      }
    });

    return dataPrep;

  };

  if (!liveData) {
    return (
      <></>
    )
  } else {
    if (!appData) {
      setAppData(prepAppData(liveData));
    }
  };


  





  /* let focusedDevice = liveData.data.map.devicelist.find((item) => {
    return item.deviceid == '34020000001180000218';
  }); */



  return (
    appData && (
      <div id="wk-dashboard">

        <WKMap data={appData.map} newAlarm={newAlarmData}></WKMap>

        <div id="wk-dashboard-left">

          <CO2 demoMode={demoMode}></CO2>

          <ConnectAll demoMode={demoMode}></ConnectAll>

          <Mesh data={appData.mesh} demoMode={demoMode}></Mesh>

        </div>

        <div id="wk-dashboard-center">
          <Header data={appData.header}></Header>
          <div className='wk-demomode-trigger' onClick={(e)=>{setDemoMode(!demoMode)}}>演示模式：【{demoMode?'开':'关'}】</div>
        </div>

        <div id="wk-dashboard-right">

          <Discover demoMode={demoMode}></Discover>

          <Alarm data={appData.alarm} newAlarm={newAlarmData} demoMode={demoMode}></Alarm>

          <Ranking demoMode={demoMode}></Ranking>
        </div>
      </div>
    )
  );
}

export default App;
// export default MapApiLoaderHOC({ak: 'RxWiGvU9wOVoTvOc55Hk1to7484ThfGX'})(App);