import { useState } from 'react';
import ReactECharts from 'echarts-for-react';

export default function Discover(props) {
    let dataFetched = {

    };

    let chartOption = {
        /* 
        tooltip: {
            trigger: 'item'
        },
        legend: {
            bottom: '5%',
            left: 'center'
        }, */
        series: [
            {
                name: '分类统计',
                type: 'pie',
                radius: ['80%', '95%'],
                
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#000',
                    borderWidth: 2
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 40,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    { value: 1271, name: '智能' },
                    { value: 321, name: '车流' },
                    { value: 101, name: '人流' },
                    { value: 152, name: '设备' },
                    { value: 76, name: '环境' },
                ]
            }
        ]


    };

    return (
        <div id="wk-dashboard-widget-discover" className="wk-dashboard-widget">
            <h2 className="wk-c-title">
                <span className="wk-c-icon"><img src="./images/icon-discover.png" /></span> <span className="wk-c-text">智能发现</span>
            </h2>
            <div className="wk-c-content">
                <div className="wk-m-layout-2cols">
                    <div className="wk-c-dataitems">
                        <div className="wk-c-dataitem wk-m-dataitem">
                            <div className="wk-c-fixedwidth">智能告警</div> <span className="wk-c-highlight wk-f-countup">1271</span>
                        </div>
                        <div className="wk-c-dataitem wk-m-dataitem">
                            <div className="wk-c-fixedwidth">车流告警</div> <span className="wk-c-highlight wk-f-countup">321</span>
                        </div>
                        <div className="wk-c-dataitem wk-m-dataitem">
                            <div className="wk-c-fixedwidth">人流告警</div> <span className="wk-c-highlight wk-f-countup">101</span>
                        </div>
                        <div className="wk-c-dataitem wk-m-dataitem">
                            <div className="wk-c-fixedwidth">设备告警</div> <span className="wk-c-highlight wk-f-countup">152</span>
                        </div>
                        <div className="wk-c-dataitem wk-m-dataitem">
                            <div className="wk-c-fixedwidth">环境告警</div> <span className="wk-c-highlight wk-f-countup">76</span>
                        </div>
                    </div>
                    <div id="wk-chart-discover">
                        <ReactECharts option={chartOption} style={{ height: '10rem' }} />
                    </div>
                </div>
                <div>
                    <table className="wk-m-datagrid wk-datagrid-alarm" style={{'textAlign':'center'}}>
                        <tbody>
                            <tr style={{opacity: .75}}>
                                <td>发现</td>
                                <td>立案</td>
                                <td>派遣</td>
                                <td>处置</td>
                                <td>核查</td>
                                <td>结案</td>
                            </tr>
                            <tr style={{color: '#FCEE21'}}>
                                <td>8</td>
                                <td>6</td>
                                <td>7</td>
                                <td>16</td>
                                <td>10</td>
                                <td>1874</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}