import { useState, useRef, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import _ from 'lodash';

export default function Mesh(props) {

    const [viewingMode, setViewingMode] = useState([
        'pm2_5', 'co2'
    ]);
    const interval_01 = useRef(null);
    const interval_02 = useRef(null);
    const interval_03 = useRef(null);
    const interval_04 = useRef(null);
    const interval_05 = useRef(null);
    const interval_06 = useRef(null);
    const interval_07 = useRef(null);

    /* {
        'pm2_5': true,
        'pm10': false,
        'noise': false,
        'co2': false,
        'traffic': false,
        'pedestrian': false,
        'congestion': false,
    } */

    let dataDefault = {
        pm2_5: {
            name: 'PM2.5',
            current: 110,
            data: [65, 60, 64, 64, 82, 114, 105, 57, 101, 57, 120, 96],
        },
        pm10: {
            name: 'PM10',
            current: 73,
            data: [66, 75, 81, 115, 41, 28, 97, 33, 101, 78, 117, 98],
        },
        noise: {
            name: '噪音',
            current: 67,
            data: [61, 67, 61, 60, 69, 70, 64, 67, 70, 63, 68, 61],
        },
        co2: {
            name: 'CO2',
            current: 441,
            data: [447, 441, 430, 429, 444, 420, 423, 444, 437, 422, 448, 439],
        },
        traffic: {
            name: '车流',
            current: 23,
            data: [40, 49, 43, 40, 42, 42, 42, 47, 43, 50, 48, 45],
        },
        pedestrian: {
            name: '人流',
            current: 34,
            data: [52, 55, 46, 48, 48, 49, 51, 50, 54, 45, 53, 53],
        },
        congestion: {
            name: '堵车',
            current: 2,
            data: [1.98, 1.46, 1.96, 1.69, 2.17, 1.84, 2.09, 1.42, 1.75, 1.55, 1.48, 1.92],
        },
    };

    if (props.data) {
        _.merge(dataDefault, props.data);
    }

    let chartOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        /* legend: {
          data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
        }, */
        /* toolbox: {
          feature: {
            saveAsImage: {}
          }
        }, */
        grid: {
            left: '2%',
            right: '0',
            bottom: '0',
            top: '10%',
            containLabel: false
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                axisLine: {
                    show: false,
                },
                data: ['202205', '202206', '202207', '202208', '202209', '202210', '202211', '202212', '202301', '202302', '202303', '202304']
            }
        ],
        yAxis: [
            {
                type: 'value',
                axisLine: {
                    show: false,
                },
                splitLine: {
                    show: false,
                }
            }
        ],
        series: [
        ]
    };

    let defaultSerieOption = {
        type: 'line',
        label: {
            show: false,
            position: 'top'
        },
        symbol: 'none',
        areaStyle: {
            // color: '#CD3DFC',
            opacity: .5,
        },
        lineStyle: {
            width: 3,
            // color: '#CD3DFC',
        },
        smooth: true,
        emphasis: {
            focus: 'series'
        },
    }

    function addViewingMode(newMode) {
        // setViewingMode((viewingMode.slice(1)).push(newMode));
        if (viewingMode.includes(newMode)) return;
        setViewingMode((viewingMode.slice(1)).concat([newMode]));
    }



    chartOption.series[0] = _.merge({
        name: dataDefault[viewingMode[0]].name,
        data: dataDefault[viewingMode[0]].data,
    }, defaultSerieOption);

    chartOption.series[1] = _.merge({
        name: dataDefault[viewingMode[1]].name,
        data: dataDefault[viewingMode[1]].data,
    }, defaultSerieOption);

    const dropdownOptions = Object.entries(dataDefault).map((item) =>
        <option value={item[0]}>{item[1].name}</option>
    );


    useEffect(() => {
        if (interval_01.current) clearInterval(interval_01.current);
        if (interval_02.current) clearInterval(interval_02.current);
        if (interval_03.current) clearInterval(interval_03.current);
        if (interval_04.current) clearInterval(interval_04.current);
        if (interval_05.current) clearInterval(interval_05.current);
        if (interval_06.current) clearInterval(interval_06.current);
        if (interval_07.current) clearInterval(interval_07.current);
        if (props.demoMode) {
            setTimeout(() => {
                addViewingMode('congestion');
            }, 3000);
            interval_01.current = setInterval(() => {
                setTimeout(() => {
                    addViewingMode('congestion');
                }, 3000);
            }, 42000);

            setTimeout(() => {
                addViewingMode('pm10');
            }, 9000);
            interval_01.current = setInterval(() => {
                setTimeout(() => {
                    addViewingMode('pm10');
                }, 9000);
            }, 42000);

            setTimeout(() => {
                addViewingMode('noise');
            }, 15000);
            interval_01.current = setInterval(() => {
                setTimeout(() => {
                    addViewingMode('noise');
                }, 15000);
            }, 42000);

            setTimeout(() => {
                addViewingMode('co2');
            }, 21000);
            interval_01.current = setInterval(() => {
                setTimeout(() => {
                    addViewingMode('co2');
                }, 21000);
            }, 42000);

            setTimeout(() => {
                addViewingMode('traffic');
            }, 27000);
            interval_01.current = setInterval(() => {
                setTimeout(() => {
                    addViewingMode('traffic');
                }, 27000);
            }, 42000);

            setTimeout(() => {
                addViewingMode('pedestrian');
            }, 33000);
            interval_01.current = setInterval(() => {
                setTimeout(() => {
                    addViewingMode('pedestrian');
                }, 33000);
            }, 42000);

            setTimeout(() => {
                addViewingMode('pm2_5');
            }, 39000);
            interval_01.current = setInterval(() => {
                setTimeout(() => {
                    addViewingMode('pm2_5');
                }, 39000);
            }, 42000);
        }
    }, [props.demoMode]);

    return (
        <div id="wk-dashboard-widget-mesh" className="wk-dashboard-widget">
            <h2 className="wk-c-title">
                <span className="wk-c-icon"><img src="./images/icon-mesh.png" /></span> <span className="wk-c-text">数据融合</span>
            </h2>
            <div className="wk-c-content">
                <div className="wk-m-layout-2cols">
                    <div className="wk-c-dataitems">
                        <div className={"wk-c-dataitem wk-m-dataitem " + (viewingMode.includes('pm2_5') ? ('wk-a-active') : (''))} onClick={e => { addViewingMode('pm2_5'); }}>
                            <span className="wk-c-fixedwidth">PM2.5</span> <span className="wk-c-highlight wk-f-countup">{dataDefault.pm2_5.current}</span> <span>ug/m3</span>
                        </div>
                        <div className={"wk-c-dataitem wk-m-dataitem " + (viewingMode.includes('pm10') ? ('wk-a-active') : (''))} onClick={e => { addViewingMode('pm10'); }}>
                            <span className="wk-c-fixedwidth">PM10</span> <span className="wk-c-highlight wk-f-countup">{dataDefault.pm10.current}</span> <span>ug/m3</span>
                        </div>
                        <div className={"wk-c-dataitem wk-m-dataitem " + (viewingMode.includes('noise') ? ('wk-a-active') : (''))} onClick={e => { addViewingMode('noise'); }}>
                            <span className="wk-c-fixedwidth">噪音</span> <span className="wk-c-highlight wk-f-countup">{dataDefault.noise.current}</span> <span>分贝</span>
                        </div>
                        <div className={"wk-c-dataitem wk-m-dataitem " + (viewingMode.includes('co2') ? ('wk-a-active') : (''))} onClick={e => { addViewingMode('co2'); }}>
                            <span className="wk-c-fixedwidth">CO2</span> <span className="wk-c-highlight wk-f-countup">{dataDefault.co2.current}</span> <span>m3</span>
                        </div>
                    </div>
                    <div className="wk-c-dataitems">
                        <div className={"wk-c-dataitem wk-m-dataitem " + (viewingMode.includes('traffic') ? ('wk-a-active') : (''))} onClick={e => { addViewingMode('traffic'); }}>
                            <span className="wk-c-fixedwidth">车流 </span> <span className="wk-c-highlight wk-f-countup">{dataDefault.traffic.current}</span>
                        </div>
                        <div className={"wk-c-dataitem wk-m-dataitem " + (viewingMode.includes('pedestrian') ? ('wk-a-active') : (''))} onClick={e => { addViewingMode('pedestrian'); }}>
                            <span className="wk-c-fixedwidth">人流 </span> <span className="wk-c-highlight wk-f-countup">{dataDefault.pedestrian.current}</span>
                        </div>
                        <div className={"wk-c-dataitem wk-m-dataitem " + (viewingMode.includes('congestion') ? ('wk-a-active') : (''))} onClick={e => { addViewingMode('congestion'); }}>
                            <span className="wk-c-fixedwidth">堵车 </span> <span className="wk-c-highlight wk-f-countup">{dataDefault.congestion.current}</span>
                        </div>
                    </div>
                </div>

                {/* <div style={{opacity:.5}}>
                    {dataDefault[viewingMode].name}月趋势
                </div> */}
                {/* <select value={viewingMode} onChange={e=>{
                        setViewingMode(e.target.value);
                    }}>{dropdownOptions}</select> */}

                <div id="wk-chart-mesh">
                    <ReactECharts option={chartOption} style={{ height: '8rem' }} />
                </div>
            </div>
        </div>
    );
}