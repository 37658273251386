import { useState } from 'react';
import ReactECharts from 'echarts-for-react';

export default function ConnectAll(props) {
    let dataFetched = {
        devices: {
            done: 8785,
            due: 10000,
            potential: '17%',
            chartOption: {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    bottom: 0,
                    left: 'center',
                    textStyle: {
                        color: '#fff',
                    }
                },
                series: [
                    {
                        name: '设备建设',
                        type: 'pie',
                        radius: ['10%', '60%'],
                        center: ['50%', '30%'],
                        // roseType: 'area',
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 5,
                            borderColor: '#000',
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 40,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: 72, name: '通行区' },
                            { value: 124, name: '商业区' },
                            { value: 156, name: '居住区' },
                            { value: 59, name: '综合区' },
                        ]
                    }
                ]
            }
        },
        coveredArea: {
            done: 12,
            due: 0,
            potential: '50%',
        },
        partnerDevices: {
            done: 218,
            due: 0,
            diff: '17%',
        },
        partnerAPIs: {
            done: 5,
            due: 28,
            diff: '17%',
        }

    };
    return (
        <div id="wk-dashboard-widget-connectall" className="wk-dashboard-widget">
            <h2 className="wk-c-title">
                <span className="wk-c-icon"><img src="./images/icon-link.png" /></span> <span className="wk-c-text">万物互联</span>
            </h2>
            <div className="wk-c-content">
                <div className="wk-m-layout-2cols">
                    <div className="wk-c-dataitems">
                        <div className="wk-c-dataitem wk-m-dataitem">
                            <span className="wk-c-highlight wk-f-countup">8785</span> 已建微脑
                        </div>
                        <div className="wk-c-dataitem wk-m-dataitem">
                            <span className="wk-c-highlight wk-f-countup">905</span> km2 覆盖面积
                        </div>
                        <div className="wk-c-dataitem wk-m-dataitem">
                            <span className="wk-c-highlight wk-f-countup">28785</span> 物联设备
                        </div>
                        <div className="wk-c-dataitem wk-m-dataitem">
                            <span className="wk-c-highlight wk-f-countup">28</span> 对接系统
                        </div>
                    </div>
                    <div id="wk-chart-device">
                        <ReactECharts option={dataFetched.devices.chartOption} style={{ height: '10rem' }} />
                    </div>
                </div>
            </div>
        </div>
    );
}