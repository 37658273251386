import { useState, useEffect, useRef } from 'react';
import { Map, Marker, NavigationControl, InfoWindow, MapApiLoaderHOC, CustomOverlay } from 'react-bmapgl';
import FlvJs from 'flv.js';

function WKMap(props) {
    var videoElement = useRef(null);

    // let props.data.devicelist = props.data.devicelist || [];


    const [vURLToken, setVURLToken] = useState('loading');  //loading
    const [vLiveDataStatus, setVLiveDataStatus] = useState('loading');  //loading

    const [viewingDevice, setViewingDevice] = useState((props.data.devicelist[0]['devices'][0])?(props.data.devicelist[0]['devices'][0]['id']):null);
    const [focusedDevice, setFocusedDevice] = useState(null);
    const [focusedDeviceView, setFocusedDeviceView] = useState('device');

    // const [selectedDevices, setSelectedDevices] = useState(null);
    const selectedDevices = useRef(null);
    const allDevices = useRef(null);
    // const focusedDeviceView = useRef('device');

    const [selectedGroup, setSelectedGroup] = useState(props.data.devicelist[0]['group']);
    const [selectedDeviceType, setSelectedDeviceType] = useState('all');
    const [selectedAlarmType, setSelectedAlarmType] = useState('all');


    let BMapGL = window.BMapGL;

    let allDevicesCollect = [];
    let toBeSelectedDevices = [];
    props.data.devicelist.forEach((item) => {
        item.devices.forEach((device) => {
            allDevicesCollect.push(device);
            //(selectedDeviceType == 'all' || device.type == selectedDeviceType) &&
            if (item.group == selectedGroup && (selectedDeviceType == 'all' || (selectedDeviceType == device.type)) && (selectedAlarmType == 'all' || (selectedAlarmType == 'alarm' && device.alarm))) {
                toBeSelectedDevices.push(device);
            }
        });
    });
    if(focusedDevice)toBeSelectedDevices.push(focusedDevice);
    selectedDevices.current = toBeSelectedDevices;
    allDevices.current = allDevicesCollect;


    useEffect(() => {
        if (selectedDevices.current && selectedDevices.current.length>0 && selectedDevices.current[selectedDevices.current.length - 1].alarm && selectedDevices.current[selectedDevices.current.length - 1].alarm.length > 0) {
            setFocusedDevice(selectedDevices.current[selectedDevices.current.length - 1]);
            setFocusedDeviceView('alarm');
        }
    }, []);

    useEffect(() => {

        if (props.newAlarm) {

            let thisAlarmDevice = allDevices.current.find(item => { return item.deviceid && item.deviceid == props.newAlarm.deviceid });

            if (props.newAlarm && props.newAlarm.deviceid && thisAlarmDevice && thisAlarmDevice.alarm && !thisAlarmDevice.alarm.find(item => { return item.id == props.newAlarm.id })) {
                thisAlarmDevice.alarm.unshift(props.newAlarm);
                console.log('——告警已添加至此设备：', thisAlarmDevice);
                if(!selectedDevices.current.includes(thisAlarmDevice)){
                    selectedDevices.current = selectedDevices.current.push(thisAlarmDevice);
                    console.log('——设备在地图视野外，即将移动地图');
                }
                setFocusedDevice(thisAlarmDevice);
                setFocusedDeviceView('alarm');
                console.log('——地图已移动至告警设备');
            }else{
                console.log('——此条告警已存在，地图未变化');
            }
        }
    }, [props.newAlarm]);







    let initialCoordinates = null;
    if (selectedDevices.current && selectedDevices.current[selectedDevices.current.length - 1] && selectedDevices.current[selectedDevices.current.length - 1].coordinates) {
        initialCoordinates = selectedDevices.current[selectedDevices.current.length - 1].coordinates.split(',');
    } else {
        initialCoordinates = [121.5073547, 31.1099243];
    }



    focusedDevice && (focusedDevice.coordinatesSplit = focusedDevice.coordinates?focusedDevice.coordinates.split(','):null);

    let latestAlarm;
    if(props.newAlarm){
        latestAlarm = props.newAlarm;
    }else if(focusedDevice && focusedDevice.alarm && focusedDevice.alarm.length > 0){
        latestAlarm = focusedDevice.alarm[0];
    }


    let deviceHasAlarm = (device) => {
        return device && device.alarm && device.alarm.length > 0;
    };

    let focusDevice = (device) => {
        setFocusedDevice(device);
        if (deviceHasAlarm(focusedDevice)) {
            setFocusedDeviceView('alarm');
        } else {
            setFocusedDeviceView('device');
        }
    };




    let markers = [];
    selectedDevices.current && (markers = selectedDevices.current.map((device) => {
        if(!device.coordinates){
            return <></>;
        }
        let coordinates = device.coordinates.split(',');
        return (
            <Marker
                position={new BMapGL.Point(coordinates[0], coordinates[1])}
                icon={new BMapGL.Icon(device.alarm && device.alarm.length > 0 ? ("./images/device-icon-alarm.gif") : ("./images/device-icon.png"), new BMapGL.Size(34, 54), {})}
                key={device.id}
                // customData={{device:device}}
                onClick={e => {
                    focusDevice(device);
                }}
            />
        )
    }));

    let areaOptions = props.data.devicelist.map((item, index) => {
        return (
            <option key={index} value={item.group}>{item.group}</option>
        )
    });

    /* useEffect(() => {
        if (vURLToken == 'loading') {
            fetch(`http://139.196.53.184:88/api/live/user/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: 'username=13665180299&respondjson=1',
            }).then((response) => response.json()).then(responseJson => {
                let liveURLToken = responseJson.data.URLToken || null;
                //token failed
                if (!liveURLToken) {
                    console.log('video login failed: video URLToken not found');
                    setVURLToken(null);
                    return;
                }
                //token OK
                console.log('video URLToken OK');
                setVURLToken(liveURLToken);
            });
        }
    }, [vURLToken]);



    function showLiveVideo(device) {
        // fetch('/data/device.json')
        let url= (!focusedDevice || (focusedDevice.deviceid && focusedDevice.deviceid ==='34020000001180000218'))?('/data/device.json'):('http://139.196.53.184/equ/v1/stream/start');
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                serial: device.deviceid,
                channel: 2,
                URLToken: vURLToken
            }),
        }).then((response) => response.json()).then(responseJson => {
            console.log('/equ/v1/stream/start responseJson: '+responseJson);
            let flvPlayer = FlvJs.createPlayer({
                type: 'flv',
                isLive: true,
                hasAudio: false,
                url: responseJson.WS_FLV
            });
            flvPlayer.attachMediaElement(videoElement.current);
            flvPlayer.load();
            // flvPlayer.play();
        });
    }

    function showDefaultVideo() {
        videoElement.current.setAttribute('src', 'images/video-sample.mp4');
    }


    useEffect(() => {

        if (!videoElement.current) return;

        if (vURLToken && vURLToken !== 'loading') {
            console.log('using live video');
            showLiveVideo(focusedDevice);
        } else {
            console.log('using default video');
            showDefaultVideo();
        }
    }, [focusedDevice]); */



    return (
        <div id="wk-dashboard-map">
            <div id="wk-dashboard-map-toolbar">
                <label>区域</label>
                <select name="wk-f-group" id="wk-f-group" value={selectedGroup} onChange={e => {
                    setSelectedGroup(e.target.value);
                }}>
                    {areaOptions}
                </select>

                <label>类型</label>
                <select name="wk-f-type" id="wk-f-type" onChange={e => {
                    setSelectedDeviceType(e.target.value);
                }}>
                    <option value="all">All</option>
                    <option value="照明">照明</option>
                    <option value="智能+照明">智能+照明</option>
                </select>

                <label>告警</label>
                <select name="wk-f-alarm" id="wk-f-alarm" onChange={e => {
                    setSelectedAlarmType(e.target.value);
                }}>
                    <option value="all">All</option>
                    <option value="alarm">正在告警</option>
                </select>

            </div>
            <Map
                style={{ height: '100%' }}
                center={new BMapGL.Point(initialCoordinates[0], initialCoordinates[1])}
                zoom={15}
                tilt={50}
                // heading={64.5}
                enableScrollWheelZoom={true}
                mapStyleV2={{ styleId: '0b3beceacdd38bbb6bf949625cfc3b65' }}
            >
                {markers}

                {focusedDevice && focusedDevice.coordinatesSplit && (
                    <CustomOverlay
                        position={new BMapGL.Point(focusedDevice.coordinatesSplit[0], focusedDevice.coordinatesSplit[1])}
                        offsetX={260}
                        offsetY={180}
                    >
                        <div id="wk-device-detail">
                            <div className='wk-c-close' onClick={(e) => { setFocusedDevice(null) }}>X</div>
                            <div className="wk-c-title">
                                <div className="wk-c-dataitem wk-m-dataitem">
                                    <div className="wk-c-fixedwidth">设备编号</div> <span className="wk-c-highlight">{(focusedDevice.deviceid ? focusedDevice.deviceid : focusedDevice.id)}</span>
                                </div>
                            </div>
                            <div className="wk-c-content">

                                {/* <video controls width={'100%'} ref={videoElement}></video> */}

                                {deviceHasAlarm(focusedDevice) && latestAlarm && latestAlarm.images && (
                                    <div className="wk-c-video"><a rel="noreferrer" href={'http://139.196.53.184:88/' + (latestAlarm.images.split(','))[0]} target='_blank'><img alt={latestAlarm.name} src={'http://139.196.53.184:88/' + (latestAlarm.images.split(','))[0]}></img></a></div>
                                )}
                                <div>


                                    <div className='wk-m-tabs'>
                                        {deviceHasAlarm(focusedDevice) && (
                                            <span onClick={(e) => { setFocusedDeviceView('alarm') }} className={focusedDeviceView == 'alarm' ? 'wk-a-active' : ''}>告警信息</span>
                                        )}
                                        <span onClick={(e) => { setFocusedDeviceView('device') }} className={focusedDeviceView == 'device' ? 'wk-a-active' : ''}>设备信息</span>
                                    </div>

                                    <div className='wk-m-tabs-ctnrs'>

                                        {(deviceHasAlarm(focusedDevice) && focusedDeviceView == 'alarm') && (

                                            <div className="wk-c-dataitems wk-a-active">
                                                <div className="wk-c-dataitem wk-m-dataitem">
                                                    <div className="wk-c-fixedwidth">告警原因 </div> <span className="wk-c-highlight">{latestAlarm.name}</span>
                                                </div>
                                                <div className="wk-c-dataitem wk-m-dataitem">
                                                    <div className="wk-c-fixedwidth">告警区域 </div> <span className="wk-c-highlight">{latestAlarm.locationaddress}</span>
                                                </div>
                                                <div className="wk-c-dataitem wk-m-dataitem">
                                                    <div className="wk-c-fixedwidth">告警时间 </div> <span className="wk-c-highlight">{latestAlarm.alarmtime}</span>
                                                </div>
                                            </div>
                                        )}

                                        {(focusedDeviceView == 'device') && (

                                            <div className={"wk-c-dataitems" + (focusedDeviceView == 'device' ? ' wk-a-active' : '')}>
                                                <div className="wk-c-dataitem wk-m-dataitem">
                                                    <div className="wk-c-fixedwidth">设备状态 </div> <span className="wk-c-highlight">在线</span>
                                                </div>
                                                <div className="wk-c-dataitem wk-m-dataitem">
                                                    <div className="wk-c-fixedwidth">设备类型 </div> <span className="wk-c-highlight">全功能</span>
                                                </div>
                                                <div className="wk-c-dataitem wk-m-dataitem">
                                                    <div className="wk-c-fixedwidth">物联接入 </div> <span className="wk-c-highlight">井盖3个、消防栓2个</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </CustomOverlay>
                )}

            </Map>
        </div>
    );
}

// export default WKMap;
export default MapApiLoaderHOC({ ak: 'RxWiGvU9wOVoTvOc55Hk1to7484ThfGX' })(WKMap)