import { useState } from 'react';
import ReactECharts from 'echarts-for-react';

export default function Alarm(props) {

  let fetchedDataRows = props.data.alarmlist.map(alarm => {
    return (
      <tr key={alarm.id}>
        <td><span className="wk-c-priority wk-a-high"></span></td>
        <td title={alarm.locationaddress}>{alarm.device_region}</td>
        <td title={alarm.alarmtime}>{alarm.alarmtime.slice(11,16)}</td>
        <td>{alarm.name}</td>
        {/* <td><span className="wk-m-tag-grey">待处理</span></td> */}
      </tr>
    )
  })

  let existingAlarmIDs = props.data.alarmlist.map((alarm)=>{
    return alarm.id;
  });
  
  if(props.newAlarm && props.newAlarm.id && !existingAlarmIDs.includes(props.newAlarm.id)){
    fetchedDataRows.unshift(
      <tr key={props.newAlarm.id}>
        <td><span className="wk-c-priority wk-a-high"></span></td>
        <td title={props.newAlarm.locationaddress}>{props.newAlarm.device_region}</td>
        <td title={props.newAlarm.alarmtime}>{props.newAlarm.alarmtime.slice(11,16)}</td>
        <td>{props.newAlarm.name}</td>
      </tr>
    );
  }
  fetchedDataRows = fetchedDataRows.slice(0,5);

  return (
    <div id="wk-dashboard-widget-alarm" className="wk-dashboard-widget">
      <h2 className="wk-c-title">
        <span className="wk-c-icon"><img src="./images/icon-alarm.gif" /></span> <span className="wk-c-text">实时告警</span>
      </h2>
      <div className="wk-c-content">
        <table className="wk-m-datagrid wk-datagrid-alarm">
          <tbody>
            {fetchedDataRows}
            {/*<tr>
              <td><span className="wk-c-priority wk-a-high"></span></td>
              <td>丰翔路</td>
              <td>9:55</td>
              <td>跨门营业</td>
              <td><span className="wk-m-tag-grey">待处理</span></td>
            </tr>
            <tr>
              <td><span className="wk-c-priority wk-a-normal"></span></td>
              <td>双丁路</td>
              <td>9:17</td>
              <td>人流拥挤</td>
              <td><span className="wk-m-tag-grey">待处理</span></td>
            </tr>
            <tr>
              <td><span className="wk-c-priority wk-a-normal"></span></td>
              <td>两港大道</td>
              <td>8:52</td>
              <td>车流拥堵</td>
              <td><span className="wk-m-tag-grey">待处理</span></td>
            </tr>
             <tr>
              <td><span className="wk-c-priority wk-a-high"></span></td>
              <td>丰翔路</td>
              <td>8:45</td>
              <td>违规停车</td>
              <td><span className="wk-m-tag-grey">待处理</span></td>
            </tr>
            <tr>
              <td><span className="wk-c-priority wk-a-normal"></span></td>
              <td>水产西路</td>
              <td>8:24</td>
              <td>设备故障</td>
              <td><span className="wk-m-tag-grey">待处理</span></td>
            </tr>
            <tr>
              <td><span className="wk-c-priority wk-a-high"></span></td>
              <td>云平路</td>
              <td>8:01</td>
              <td>环境告警</td>
              <td><span className="wk-m-tag-grey">待处理</span></td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}