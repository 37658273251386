import { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';

export default function Header(props) {
    let dataFetched = {

    };
    // console.log('Header data: ', props.data);


    const [weatherDataStatus, setWeatherDataStatus] = useState('loading');  //loading, failed
    const [weatherData, setWeatherData] = useState(null);

    const skycons = {
        "CLEAR_DAY": "晴（白天）",
        "CLEAR_NIGHT": "晴（夜间）",
        "PARTLY_CLOUDY_DAY": "多云（白天）",
        "PARTLY_CLOUDY_NIGHT": "多云（夜间）",
        "CLOUDY": "阴",
        "LIGHT_HAZE": "轻度雾霾",
        "MODERATE_HAZE": "中度雾霾",
        "HEAVY_HAZE": "重度雾霾",
        "LIGHT_RAIN": "小雨",
        "MODERATE_RAIN": "中雨",
        "HEAVY_RAIN": "大雨",
        "STORM_RAIN": "暴雨",
        "FOG": "雾",
        "LIGHT_SNOW": "小雪",
        "MODERATE_SNOW": "中雪",
        "HEAVY_SNOW": "大雪",
        "STORM_SNOW": "暴雪",
        "DUST": "浮尘",
        "SAND": "沙尘",
        "WIND": "大风",
    };

    useEffect(() => {
        if (weatherDataStatus == 'loading') {


            window._AMapSecurityConfig = {
                securityJsCode:'30a7904af3cf64c79bcb9986daa8e698',
            }

            // Caiyun https://api.caiyunapp.com/v2.6/jOL5FrnH4uoXiMMD/121.5007,31.2398/realtime 
            fetch('https://restapi.amap.com/v3/weather/weatherInfo?key=347f0d0365b9b90e94a7a0e96bf95bc3&city=310000&extensions=base').then((response) => response.json()).then(responseJson => {

            setWeatherDataStatus('loaded');
                responseJson.lives && responseJson.lives[0] && setWeatherData(responseJson.lives[0]);
                
            })
        }
    }, [weatherDataStatus]);



    return (
        <div id="wk-dashboard-header">
            <div className='wk-c-meta'>
                <span className='wk-c-user'>{props.data.logininfo.name || '新用户'}</span> 
                <span className='wk-c-time'>{new Date().toJSON().slice(0, 10)}</span> 
                <span className='wk-c-weather'>上海 {weatherData && ((weatherData.weather??' ') + (weatherData.temperature?' '+weatherData.temperature+'℃':'') + (weatherData.humidity?' | 湿度 '+weatherData.humidity+'%rh':' '))}</span>
            </div>
            <h1 className="wk-c-headline"><a href="http://139.196.53.184/dashboard">城市微脑</a>数据大屏</h1>
        </div>
    );
}