import { useEffect, useRef, useState } from 'react';
import ReactECharts from 'echarts-for-react';

export default function CO2(props) {

  const [viewingMode, setViewingMode] = useState('lighting');
  const interval_01 = useRef(null);
  const interval_02 = useRef(null);
  const interval_03 = useRef(null);

  let dataFetched = {
    lighting: {
      co2reduction: 905,
      chartOption: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '2%',
          right: '0',
          bottom: '0',
          top: '0',
          containLabel: false
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine: {
              show: false,
            },
            data: ['202205', '202206', '202207', '202208', '202209', '202210', '202211', '202212', '202301', '202302', '202303', '202304']
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: 'CO2 Reduction',
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            }
          },
          {
            type: 'value',
            name: 'Devices',
            axisLine: {
              show: false,
            },
            max: 1200,
            position: 'right',
            splitLine: {
              show: false,
            }
          }
        ],
        series: [
          {
            name: '总耗能',
            type: 'line',
            // stack: 'Total',
            label: {
              show: false,
              position: 'top'
            },
            symbol: 'none',
            lineStyle: {
              width: 3,
            },
            areaStyle: {
              opacity: .75,
            },
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: [1200, 1152, 1050.8, 1040.8, 1017.6, 976, 970, 897.6, 860.8, 855, 792, 760]
          },
          {
            name: '微脑数量',
            type: 'line',
            // stack: 'Total',
            yAxisIndex: 1,
            symbol: 'none',
            areaStyle: {
              opacity: .75,
            },
            lineStyle: {
              width: 3,
            },
            emphasis: {
              focus: 'series'
            },
            smooth: true,
            data: [42, 82, 150, 159, 196, 231, 236, 296, 327, 337, 385, 411]
          }

        ]
      }
    },
    construction: {
      co2reduction: 212,
      chartOption: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '2%',
          right: '0',
          bottom: '0',
          top: '0',
          containLabel: false
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine: {
              show: false,
            },
            data: ['202205', '202206', '202207', '202208', '202209', '202210', '202211', '202212', '202301', '202302', '202303', '202304']
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: 'CO2 Reduction',
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            }
          },
          {
            type: 'value',
            name: 'Devices',
            axisLine: {
              show: false,
            },
            position: 'right',
            splitLine: {
              show: false,
            }
          }
        ],
        series: [
          {
            name: '建设减碳',
            type: 'bar',
            // stack: 'Total',
            label: {
              show: false,
              position: 'top'
            },
            symbol: 'none',
            lineStyle: {
              width: 3,
            },
            areaStyle: {
              opacity: .75,
            },
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: [21, 20, 34, 4.5, 18.5, 17.5, 2.5, 30, 15.5, 5, 24, 13]
          },
          {
            name: '新建数量',
            type: 'line',
            yAxisIndex: 1,
            // stack: 'Total',
            symbol: 'none',
            areaStyle: {
              opacity: .75,
            },
            lineStyle: {
              width: 3,
            },
            emphasis: {
              focus: 'series'
            },
            smooth: true,
            data: [42, 82, 150, 159, 196, 231, 236, 296, 327, 337, 385, 411]
          }

        ]
      }
    },
    operation: {
      co2reduction: 400,
      chartOption: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '2%',
          right: '0',
          bottom: '0',
          top: '0',
          containLabel: false
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine: {
              show: false,
            },
            data: ['202205', '202206', '202207', '202208', '202209', '202210', '202211', '202212', '202301', '202302', '202303', '202304']
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: 'CO2 Reduction',
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            }
          },
          {
            type: 'value',
            name: 'Devices',
            axisLine: {
              show: false,
            },
            position: 'right',
            splitLine: {
              show: false,
            }
          }
        ],
        series: [
          {
            name: '运营减碳',
            type: 'line',
            // stack: 'Total',
            label: {
              show: false,
              position: 'top'
            },
            symbol: 'none',
            lineStyle: {
              width: 3,
            },
            areaStyle: {
              opacity: .75,
            },
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: [2, 3.72, 8.22, 12.99, 18.87, 25.8, 32.88, 41.76, 51.57, 61.68, 73.23, 85.56, ]
          },
          /* {
            name: '微脑数量',
            type: 'line',
            symbol: 'none',
            areaStyle: {
              opacity: .75,
            },
            lineStyle: {
              width: 3,
            },
            emphasis: {
              focus: 'series'
            },
            smooth: true,
            data: [42, 82, 150, 159, 196, 231, 236, 296, 327, 337, 385, 411]
          } */

        ]
      }
    }
  };

useEffect(()=>{
  if(interval_01.current)clearInterval(interval_01.current);
  if(interval_02.current)clearInterval(interval_02.current);
  if(interval_03.current)clearInterval(interval_03.current);
  
  if(props.demoMode){

    setViewingMode('construction');
    interval_01.current = setInterval(() => {
      setViewingMode('construction');
    }, 18000);

    setTimeout(() => {
      setViewingMode('operation');
    }, 6000);
    interval_01.current = setInterval(() => {
      setTimeout(() => {
        setViewingMode('operation');
      }, 6000);
    }, 18000);

    setTimeout(() => {
      setViewingMode('lighting');
    }, 12000);
    interval_01.current = setInterval(() => {
      setTimeout(() => {
        setViewingMode('lighting');
      }, 12000);
    }, 18000);
  }},[props.demoMode]);



  return (
    <div id="wk-dashboard-widget-co2" className="wk-dashboard-widget">
      <h2 className="wk-c-title">
        <span className="wk-c-icon"><img src={"./images/icon-co2.png"} /></span> <span className="wk-c-text">绿色低碳</span>
      </h2>
      <div className="wk-c-content">
        <div className="wk-c-dataitems">
          <div className={"wk-c-dataitem wk-m-dataitem "+ (viewingMode=='lighting'?'wk-a-active':'')} onClick={()=>{setViewingMode('lighting')}}>
            <span className="wk-m-block"><span className="wk-c-highlight wk-f-countup">{dataFetched.lighting.co2reduction}</span> 吨</span>
            <span className="wk-c-label">照明减碳</span>
          </div>
          <div className={"wk-c-dataitem wk-m-dataitem "+ (viewingMode=='construction'?'wk-a-active':'')} onClick={()=>{setViewingMode('construction')}}>
            <span className="wk-m-block"><span className="wk-c-highlight wk-f-countup">{dataFetched.construction.co2reduction}</span> 吨</span>
            <span className="wk-c-label">建设减碳</span>
          </div>
          <div className={"wk-c-dataitem wk-m-dataitem "+ (viewingMode=='operation'?'wk-a-active':'')} onClick={()=>{setViewingMode('operation')}}>
            <span className="wk-m-block"><span className="wk-c-highlight wk-f-countup">{dataFetched.operation.co2reduction}</span> 吨</span>
            <span className="wk-c-label">运营减碳</span>
          </div>
        </div>
        <div id="wk-chart-co2">
          { 
            viewingMode == 'lighting' && <ReactECharts option={dataFetched.lighting.chartOption} style={{ height: '8rem' }} />
          }
          { 
            viewingMode == 'construction' && <ReactECharts option={dataFetched.construction.chartOption} style={{ height: '8rem' }} />
          }
          { 
            viewingMode == 'operation' && <ReactECharts option={dataFetched.operation.chartOption} style={{ height: '8rem' }} />
          }
        </div>
      </div>
    </div>
  );
}
